import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "../Checkbox/Checkbox";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";
import SearchIcon from "@material-ui/icons/Search";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import FullScreenDialog from "../FullScreenDialog/FullScreenDialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Home from "@material-ui/icons/Home";
import ListSubheader from "@material-ui/core/ListSubheader";

const useStyles = (theme) => ({
  playlists: {
    height: "100vh",
  },
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
    position: "inherit",
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
});

class Playlists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playlists: [],
      hasPlaylists: false,
      modalOpen: false,
      playlistOwner: "",
    };
  }

  componentDidMount() {
    const genre = this.props.match.params.genre;
    const baseUrl = process.env.REACT_APP_API;
    const options = {
      headers: {
        Authorization: process.env.REACT_APP_BEARER,
      },
    };
    fetch(`${baseUrl}/playlists?genre=${genre}`, options)
      .then((res) => res.json())
      .then((data) => {
        let x = data.filter((x) => x.owner !== "Spotify");
        this.setState({ playlists: x, hasPlaylists: true });
      });
  }

  handleSetPlaylistOwner = (value) => {
    this.setState({
      playlistOwner: value,
      modalOpen: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      modalOpen: false,
      playlistOwner: "",
    });
  };

  render() {
    const { playlists, hasPlaylists, modalOpen, playlistOwner } = this.state;
    const { classes } = this.props;
    const genre = this.props.match.params.genre;

    return (
      <main className={classes.playlists}>
        {!hasPlaylists ? (
          <>
            <CircularProgress color="secondary" />
          </>
        ) : (
          <>
            <Paper style={{ marginBottom: ".33em", marginTop: "2vh" }}>
              <List
                subheader={
                  <ListSubheader className={classes.subheader}>
                    {genre
                      .split(" ")
                      .map((x) => {
                        let a = x.charAt(0).toUpperCase() + x.slice(1);
                        return a;
                      })
                      .join(" ")}
                  </ListSubheader>
                }
                className={"Playlists--instructions"}
              >
                <ListItem>
                  <ListItemIcon>
                    <PlayIcon edge="start" />
                  </ListItemIcon>
                  <ListItemText
                    id={"instructions-1"}
                    primary="Opens the playlist in Spotify"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <SearchIcon edge="start" />
                  </ListItemIcon>
                  <ListItemText
                    id={"instructions-2"}
                    primary="Searches for social media accounts"
                  />
                </ListItem>
              </List>
            </Paper>
            <Checkbox
              playlists={playlists}
              handleSetPlaylistOwner={(value) =>
                this.handleSetPlaylistOwner(value)
              }
            />
            {modalOpen && (
              <FullScreenDialog
                playlistOwner={playlistOwner}
                handleCloseModal={() => this.handleCloseModal()}
              />
            )}
          </>
        )}
        <AppBar position="fixed" color="inherit" className={classes.appBar}>
          <Toolbar>
            {/* <IconButton edge="start" color="inherit" aria-label="open drawer">
            <MenuIcon />
          </IconButton> */}
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_top"
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                value="RHJAT6YJJ87P4"
              />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                border="0"
                name="submit"
                title="PayPal - The safer, easier way to pay online!"
                alt="Donate with PayPal button"
              />
              <img
                alt=""
                border="0"
                src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
            <div className={classes.grow} />
            <Typography variant="h6" color="textPrimary">
              Playlist Prodigy
            </Typography>
            <IconButton
              color="inherit"
              onClick={() => this.props.history.push("/")}
            >
              <Home />
            </IconButton>
          </Toolbar>
        </AppBar>
      </main>
    );
  }
}

export default withStyles(useStyles)(Playlists);
