import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Genres from '../assets/genres';
import TextField from '@material-ui/core/TextField';
import './Home.css';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
  heading: {
    fontSize: "4rem",
    paddingBottom: ".75em",
    fontWeight: "bold"
  },
  search: {
    marginTop: ".5em"
  },
  instructions: {
    paddingBottom: '1em'
  }
})

function Home() {
  const [genre, setGenre] = useState('');
  const classes = useStyles();
  const history = useHistory();


  const updateGenre = (e) => {
    const index = e.innerText
    const isNull = e.getAttribute('data-option-index')
    if (isNull === null) {

      setGenre('');

    } else {

      setGenre(index);

    }
  }

  const handleSearchSubmit = () => {
    history.push(`/playlists/${genre}`)

  }
  return (
    <section className="Search">
      <Typography className={classes.heading} color="Secondary" variant="h1" >
        Playlist Prodigy
      </Typography>
      <Typography variant="h6" className={classes.instructions}>
        Search your music genre.
      </Typography>
      <Autocomplete 
        autoComplete
        options={Genres.Genres}
        renderInput={(params) => <TextField {...params} label="Genre" variant='filled'/>}
        style={{width: '300px', background: '#fff'}}
        onChange={(e) => updateGenre(e.target)}
      >
      </Autocomplete>
      <Button  
        className={classes.search}
        color='secondary' 
        variant='contained' 
        disabled={genre === '' ? true : false}
        onClick={() => handleSearchSubmit()}
      >
        Search
      </Button>
    </section>
  )
}

export default Home;
