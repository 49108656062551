import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import "./App.css";
import Playlists from "./Playlists/Playlists";
import { Switch, Route } from "react-router-dom";
import Home from "./Home/Home";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import LinearProgress from "@material-ui/core/LinearProgress";

function App() {
  const [openDonate, setOpenDonate] = useState(false);
  const [progress] = useState(15);
  const [Message, setMessage] = useState("");

  useEffect(() => {
    const openDonateModal = () => {
      setTimeout(() => {
        setOpenDonate(true);
      }, 75000);
    };
    const wakeUpServer = () => {
      const baseUrl = `${process.env.REACT_APP_API}/`;
      const options = {
        headers: {
          Authorization: process.env.REACT_APP_BEARER,
        },
      };
      fetch(`${baseUrl}/`, options)
        .then((res) => res.body)
        .then((data) => {
          setMessage(data);
        });
    };
    wakeUpServer();
    openDonateModal();
  }, []);
  const handleCloseDonate = () => {
    setOpenDonate(false);
  };

  function renderRoutes() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/playlists/:genre" component={Playlists} />
      </Switch>
    );
  }

  return (
    <div className="App">
      {openDonate && (
        <Dialog
          open={openDonate}
          onClose={handleCloseDonate}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Help keep this site alive and growing."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Having a full-time job, creating music and promoting it as an
              indie artist, and being a dad keeps me busy. This tool was created
              to help save time getting my music out there and I wanted to help
              share it with my fellow independent musicians. If you find it
              helpful, please give a little something so I can continue to host
              it for you and make it a better tool.
            </DialogContentText>
            <Grid>
              <DialogContentText>Costs paid for this month.</DialogContentText>
              <DialogContentText
                color={progress > 10 ? "primary" : "textSecondary"}
              >
                Server
              </DialogContentText>
              <DialogContentText
                color={progress > 50 ? "primary" : "textSecondary"}
              >
                Bug Fixes
              </DialogContentText>
              <DialogContentText
                color={progress > 90 ? "primary" : "textSecondary"}
              >
                New Features
              </DialogContentText>
            </Grid>
          </DialogContent>

          <LinearProgress variant="determinate" value={progress} />
          <DialogActions>
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_top"
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                value="RHJAT6YJJ87P4"
              />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                border="0"
                name="submit"
                title="PayPal - The safer, easier way to pay online!"
                alt="Donate with PayPal button"
              />
              <img
                alt=""
                border="0"
                src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>

            <Button onClick={handleCloseDonate} color="primary">
              No Thanks
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {renderRoutes()}
    </div>
  );
}

export default App;
