import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import PlayIcon from "@material-ui/icons/PlayCircleFilledOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    maxHeight: "70vh",
    backgroundColor: theme.palette.background.paper,
    marginBottom: "10vh",
    overflowY: "auto",
  },
}));

export default function CheckboxList(props) {
  const classes = useStyles();
  const playlists = props.playlists;

  const handleOpenSpotify = (value) => {
    const url = value.url;
    window.open(url, "_blank");
  };

  const handleSearchButtonClick = (value) => {
    props.handleSetPlaylistOwner(value.owner);
  };

  return (
    <List className={classes.root}>
      {playlists.map((value, i) => {
        const labelId = `checkbox-list-label-${i}`;

        return (
          <ListItem
            key={i}
            role={undefined}
            dense
            button
            onClick={() => handleOpenSpotify(value)}
          >
            <ListItemIcon>
              <PlayIcon />
            </ListItemIcon>
            <ListItemText
              id={labelId}
              primary={value.name}
              secondary={value.owner}
            />
            <ListItemSecondaryAction
              onClick={() => handleSearchButtonClick(value)}
            >
              <IconButton edge="end" aria-label="comments">
                <SearchIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}
