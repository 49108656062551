import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import LinkedIn from '@material-ui/icons/LinkedIn';
import LinearProgress from '@material-ui/core/LinearProgress';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#3b5998',
    justifyContent: 'center'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const classes = useStyles();
  const [open] = React.useState(true);
  const [profiles, setProfiles] = React.useState([]);
  const [hasProfiles, setHasProfiles] = React.useState(false);

  React.useEffect(() => {
    
    const fetchProfiles = async () => {
      const url = `${process.env.REACT_APP_API}/social?name=${props.playlistOwner}`
      const options = {
        headers: {
          "Authorization": process.env.REACT_APP_BEARER
        }
      }
      const results = await fetch(url, options).then(res => res.json())

      setProfiles(results);
      setHasProfiles(true);
    }
    
    fetchProfiles();
    

  }, [])

  const handleSearchButtonClick = (value) => {
    const url = value.socialUrl
    window.open(url, "_blank")
  };

  const handleClose = () => {
    props.handleCloseModal();
  };


  return (
    <div>
      
      <Dialog maxWidth="lg" open={open} onClose={handleClose} TransitionComponent={Transition}>
      
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {`Social Media Profiles Found: ${props.playlistOwner}`}
            </Typography>
          </Toolbar>
        </AppBar>
        {hasProfiles ?
           
            <List className={classes.root}>
              {profiles.map((value, i) => {
                const labelId = `social-media-profile-${i}`;

                return (
                  <ListItem key={i} role={undefined} dense >
                    <ListItemAvatar>
                      {!value.image ? <Avatar /> : <Avatar alt={value.name} src={value.image}/>}

                    </ListItemAvatar>
                    <ListItemText id={labelId} primary={value.name} secondary={value.userid} />
                    <ListItemSecondaryAction onClick={() => handleSearchButtonClick(value)}>
                      <IconButton edge="end" aria-label={`open ${value.userid}'s profile on ${value.network}`}>
                        {
                          value.network === 'facebook' ?  <Facebook />
                          : (value.network === 'instagram') ?  <Instagram />
                          : (value.network === 'linkedin') ?  <LinkedIn />
                          : <ListItemText id={`social-media-${i}`} primary={value.network} />

                        }
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
            
          
            : <LinearProgress color='secondary'/>}
      </Dialog>
    </div>
  );
}
